import create from "zustand";
import { devtools } from "zustand/middleware";

const clientProductStore = (set) => ({
    products: [],

  

    setClientProducts: (apiProducts) => {
        set((state) => ({
            products: [...apiProducts],
        }));
    },

    addClientProductToStore: (newProduct) => {
        set((state) => ({
            products: [...state.products, newProduct],
        }));
    },
    
    
    updateClientProductsToStore: (editData) => {
        set((state) => ({
            products: state.products.map((product) => {
                if ( product._id === editData._id) {
                    return {
                        ...editData
                    };
                } else {
                    return product
                }
            }),
        }));
    },



    bulkUpdateClientProductsToStore: (bulkUpdateData) => {
        set((state) => ({
            products: state.products.map((product) => {
                if (bulkUpdateData.product_ids.includes(product._id)) {
                    return {
                        ...product,
                        plan:bulkUpdateData.plan,
                        start_date: bulkUpdateData.start_date
                    };
                } else {
                    return product
                }
            }),
        }));
    },
});








const useClientProductStore = create(devtools(clientProductStore));

export default useClientProductStore