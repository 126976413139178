import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import useProductStore from "stores/productStore";
import useTemplateStore from "stores/templateStore";
import useVendorStore from "stores/vendorStore";
import useWarehouseStore from "stores/warehouseStore";
import ProductApi from "utils/composable/productsApi";
import TemplateApi from "utils/composable/templateApi";
import Brands from "utils/constants/brands";
const ProductForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
  duplicate,
  bulkUpdates,
  wareHouseLoading,
  vendorsLoading
}) => {
  const templates = useTemplateStore((state) => state.templates);
  const warehouses = useWarehouseStore((state) => state.warehouses);
  const vendors = useVendorStore((state) => state.vendors);
  const [form] = Form.useForm();
  const [currentFields, setCurrentfields] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(false);
  const { addTemplate, getTemplates, updateTemplates } = TemplateApi();
  const { checkBarcodeApi } = ProductApi();
  const products = useProductStore((state) => state.Products);
  const [templateLoading, setTemplateLoading] = useState("");
  const [currentBarcode, setCurrentBarcode] = useState("");
  const [currentSLNumber, setCurrentSLNumber] = useState("");
  const brands = Brands();
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const [isQtyBased, setIsQtyBased] = useState(false);

  const wareHouseList = warehouses.map((warehouse) => {
    return {
      label: warehouse.title,
      value: warehouse._id,
    };
  });
  const vendorsList = vendors.map((vendor) => {
    return {
      label: vendor.name,
      value: vendor._id,
    };
  });

  const templateList = templates.map((template) => {
    return {
      label: template.name,
      value: template._id,
    };
  });

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData?.name || "",
        brand: editData?.brand || "",
        barcode: editData?.barcode || "",
        sl_number: editData?.sl_number || "",
        description: editData?.description || "",
        category: editData?.category?._id || "",
        specifications: editData.specifications || "",
        template: editData?.template || "",
        warehouse: editData?.warehouse?._id || "",
        vendor: editData?.vendor?._id || "",
        use: editData.use || "",
        qty_based: editData.qty_based || false,
        qty: editData.qty || 0,
      });

      setCurrentfields([editData.specifications]);
    } else {
      form.setFieldsValue({
        fields: [],
        name: "",
        category: "",
      });
    }
  }, [editData]);

  useEffect(() => {
    if (!templates.length) {
      // setTableLoadings(true);

      setTemplateLoading(true);
      try {
        getTemplates()
          .then(() => {
            setTemplateLoading(false);
            // setTableLoadings(false);
          })
          .catch((err) => {
            console.log(err);
            setTemplateLoading(false);
            // setTableLoadings(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (editData) {

      var fields = Object.entries(editData?.specifications || {});
      var currentEditFields = fields.map((field) => {
        return {
          name: field[0],
          value: field[1],
          data_type:
            typeof field[1] == "boolean" ? "yes_or_no" : typeof field[1],
        };
      });


      // currentEditFields.shift();

      setCurrentfields(currentEditFields);
    }
  }, []);

  const brandList = brands.map((brand) => {
    return {
      label: brand.title,
      value: brand.value,
      key: brand._id,
    };
  });

  const onFinish = (values) => {

    if ((editData && duplicate) || !editData) {
      values.category = currentTemplate?.category;

      const {
        brand,
        barcode,
        sl_number,
        description,
        category,
        name,
        template,
        warehouse,
        vendor,
        use,
        qty_based,
        qty,
        ...specification
      } = values;

      const finalPaylaod = {
        name: name,
        brand: brand,
        barcode: barcode,
        sl_number: sl_number,
        description: description,
        category: editData?.category?._id || category?._id,
        warehouse: warehouse,
        vendor: vendor,
        specifications: specification,
        use: use,
      };
      // Add qty_based only if use case is "Sale"
      if (use === "sale") {
        finalPaylaod.qty_based = qty_based;
        finalPaylaod.qty = parseFloat(qty);
      }else{
        finalPaylaod.qty_based = false;
        finalPaylaod.qty = 0;
      }


      submitHandler(finalPaylaod, form);
    } else {
      const {
        brand,
        barcode,
        sl_number,
        description,
        category,
        name,
        template,
        warehouse,
        vendor,
        use,
        ...specification
      } = values;

      const finalEditPayload = {
        product_id: editData?._id,
        name: name,
        brand: brand,
        barcode: barcode,
        sl_number: sl_number,
        description: description,
        category: editData.category?._id,
        warehouse: warehouse,
        vendor: vendor,
        specifications: specification,
        use: use,
      };
      if (use !== "sale") {
        finalEditPayload.qty_based = false;
        finalEditPayload.qty = 0;
      }



      confirmEditHandler(finalEditPayload, form);
    }
  };

  const onTemplateChange = (e) => {


    const selectedTemplate = templates.find((template) => template._id == e);

 
    setCurrentTemplate(selectedTemplate);

    setCurrentfields(selectedTemplate.fields);

  };

  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      {!editData && (
        <Form.Item
          key={editData?._id}
          labelCol={{ span: 24, offset: 0 }}
          name="template"
          label="Select Product Template"
          rules={[
            {
              required: true,
              message: "Missing Template",
            },
          ]}
        >
          <Select
            loading={templateLoading}
            onChange={(e) => onTemplateChange(e)}
            key={editData?._id}
            options={templateList}
          />
        </Form.Item>
      )}

      <Form.Item
        style={{ marginTop: "-1rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="warehouse"
        label="Warehouse"
        rules={[
          {
            required: true,
            message: "Missing Warehouse",
          },
        ]}
      >
        <Select loading={wareHouseLoading} options={wareHouseList} />
      </Form.Item>
      <Form.Item
        style={{ marginTop: "-1rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="vendor"
        label="Vendor"
        
      >
        <Select loading={vendorsLoading} options={vendorsList} />
      </Form.Item>

      <Form.Item
        key={editData?._id}
        style={{ marginTop: "-1rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="use"
        label="Use Case"
        rules={[
          {
            required: true,
            message: "Missing use",
          },
        ]}
      >
        <Select onChange={(value) => setSelectedUseCase(value)}>
          <Select.Option value="stand-by">Stand By</Select.Option>
          <Select.Option value="rent">Rent</Select.Option>
          <Select.Option value="sale">Sale</Select.Option>
        </Select>
      </Form.Item>
      {!editData && selectedUseCase === "sale" && (
   
      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Qty Based?"
        valuePropName={"checked"}
        name="qty_based"
      >
        <Switch
          style={{ width: 60 }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(value) => setIsQtyBased(value)}
        />
      </Form.Item>
 
    )}
      {!editData && isQtyBased && (
             <Form.Item
             labelCol={{ span: 24, offset: 0 }}
             name="qty"
             label="Qty"
             style={{ width: "10rem" }}
           >
             <Input
               style={{ width: "10rem" }}
               type={"number"}
             />
           </Form.Item>
          
    )}
    
      <Form.Item
        label="Name"
        name="name"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            validator: (_, value) => {
              if (
                value &&
                /un[-_\s]?r(?:egistere[d]?|egistere[d]?)/i.test(value)
              ) {
                return Promise.reject(new Error("Invalid product name"));
              }
              return Promise.resolve();
            },
          },

          { required: true, message: "Name required" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Barcode"
        name="barcode"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            validator: (_, value) => {
              if (value && /\b0000\b/.test(value)) {
                return Promise.reject(new Error("Invalid barcode"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input onChange={(e) => setCurrentBarcode(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="SL Number"
        name="sl_number"
        labelCol={{ span: 24, offset: 0 }}
        >
        <Input onChange={(e) => setCurrentSLNumber(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Brand"
        name="brand"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: "Missing Brand Name",
          },
        ]}
      >
       <Select
          showSearch
          options={brandList}
          style={{ width: "15rem" }}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Description"
        name="description"
      >
        <TextArea />
      </Form.Item>

      {(currentTemplate || editData) && <Divider>Specifications</Divider>}

      {currentFields?.map((field, index) => {


        if (field.data_type == "yes_or_no") {


          return (
            <Form.Item
              style={{ width: "10rem" }}
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name?.toLowerCase()}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required,
                  message: "Missing ",
                },
              ]}
              initialValue={false}
            >
              <Switch
                defaultChecked={field?.value}
                style={{ width: "6rem" }}
                checkedChildren="Yes"
                unCheckedChildren="No"
              ></Switch>
            </Form.Item>
          );
        } else if (field.data_type == "date") {
      
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name?.toLowerCase()}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required,
                  message: "Missing ",
                },
              ]}
              initialValue={field?.value || ""}
            >
              <Input type="date" />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name?.toLowerCase()}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required,
                  message: "Missing ",
                },
              ]}
              initialValue={field?.value || ""}
            >
              <Input type={field.data_type} />
            </Form.Item>
          );
        }
      })}

      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button key={editData._id} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {(editData && duplicate) || !editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
