import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";

import { useEffect, useState } from "react";
import useTemplateStore from "stores/templateStore";
import TemplateApi from "utils/composable/templateApi";



import moment from "moment";
import useClientLocationStore from "stores/clientLocationStore";
import ClientLocationApi from "utils/composable/clientLocationApi";
import Brands from "utils/constants/brands";
import Plans from "utils/constants/plans";
const ClientProductForm = ({
  state,
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
  bulkUpdates,
  handleBulkUpdate,
  selectedproducts,

}) => {

  const templates = useTemplateStore((state) => state.templates);
  console.log("State in ClientProductForm", state);

  const [form] = Form.useForm();
  const [currentFields, setCurrentfields] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(false);
  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0])
  const [lastPaidDate, setLastPaidDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const { addTemplate, getTemplates, updateTemplates } = TemplateApi();
  const [templateLoading, setTemplateLoading] = useState("");

  const { getClientLocation } = ClientLocationApi();
  const plans = Plans();
  const brands = Brands();
  const clientLocations = useClientLocationStore(
    (state) => state.clientLocations
  );

  const templateList = templates.map((template) => {
    return {
      label: template.name,
      value: template._id,
    };
  });

  console.log("clientLocations", clientLocations);

  const clientLocationList = clientLocations?.map((location) => {
    return {
      label: location?.name,
      value: location?._id,
      key: location?._id,
    };
  });

  clientLocationList.push({
    label: "Primary Location",
    value: null,
    key: 1,
  });



  const planList = plans.map((plan) => {
    return {
      label: plan.title,
      value: plan.value,
      key: plan._id,
    };
  });
  const brandList = brands.map((brand) => {
    return {
      label: brand.title,
      value: brand.value,
      key: brand._id,
    };
  });


  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  useEffect(() => {
    if (!editData && !bulkUpdates) {
      getClientLocation(state.id);
    }else if (editData) {
      form.setFieldsValue({
        name: editData?.name || "",
        brand: editData?.brand || "",
        barcode: editData?.barcode || "",
        description: editData?.description || "",
        category: editData?.category?.name || "",
        specifications: editData?.specifications || "",
        template: editData?.template || "",
        warehouse: editData?.warehouse?._id || "",
        start_date: editData?.start_date ? formatDate(editData?.start_date) : moment(new Date()).format("YYYY-MM-DD"),
        last_paid_date: editData?.last_paid_date || "",
        plan: editData?.plan || "",
        last_paid_date: editData?.last_paid_date || "",
        payment_cycle: editData?.payment_cycle || " ",
        due_date: editData?.due_date || "",
        client_location: editData?.client_location?._id || null,
      });

      setCurrentfields([editData.specification_fields]);

      getClientLocation(editData.client);
    } else {
      form.setFieldsValue({
        fields: [],
        name: "",
        category: "",
        start_date: moment(new Date()).format("YYYY-MM-DD")
      });
    }
  }, [editData]);

  useEffect(() => {
    if (!templates.length) {
      setTemplateLoading(true);
      try {
        getTemplates()
          .then(() => {
            setTemplateLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setTemplateLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (editData) {

      console.log({ editData });
      var fields = Object.entries(editData?.specifications || {});
      var currentEditFields = fields?.map((field) => {




        return {
          name: field[0],
          value: field[1],
        };
      });

      console.log(fields, "fields");


      // currentEditFields.shift();
      console.log(currentEditFields, "new fields");
      setCurrentfields(currentEditFields);
    }
  }, []);

  const onTemplateChange = (e) => {


    const selectedTemplate = templates.find((template) => template._id == e);

 
    setCurrentTemplate(selectedTemplate);

    setCurrentfields(selectedTemplate.fields);

  };

  const onFinish = (values) => {
    console.log(values, "finish called")

    if (!editData && !bulkUpdates) {
      // Create new product logic
      values.category = currentTemplate?.category;
      const {
        brand,
        barcode,
        description,
        category,
        name,
        template,
        warehouse,
        payment_cycle,
        plan,
        start_date,
        last_paid_date,
        due_date,
        client_location,
        item_price,
        qty,
        charges,
        discount_percentage,
        taxes,
        ...specifications
      } = values;

      const newProductPayload = {
        client: state?.id || null,
        name:name,
        brand: brand,
        barcode: barcode,
        category:category?._id,
        specifications: specifications,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        client_location: client_location,
        plan: plan,
        is_active: true, // Default to active
        specification_fields: currentFields
      };
  
      console.log("Creating new product:", newProductPayload);
     
      submitHandler(newProductPayload, form); // Call a function to save the product
    }else if (!editData && bulkUpdates) {
      {
            values.start_date = moment(values.start_date).format('MM-DD-YYYY')
      }
      handleBulkUpdate(values, form);
    } else {
      const {
        brand,
        barcode,
        description,
        category,
        name,
        template,
        warehouse,
        payment_cycle,
        plan,
        start_date,
        last_paid_date,
        due_date,
        client_location,
        item_price,
        qty,
        charges,
        discount_percentage,
        taxes,
        ...specifications
      } = values;

      console.log(values, "Edit values ...values");
      const finalEditPayload = {
        product_id: editData?._id,
        client: editData.client,
        name: values.name,
        brand: values.brand,
        barcode: values.barcode,
        category: editData.category?._id,
        specifications: specifications,
        is_active: editData.is_active,
        start_date: startDate ? startDate: editData.start_date,
        last_paid_date: lastPaidDate ? lastPaidDate: editData.last_paid_date,
        due_date: dueDate ? dueDate : editData.due_date,
        plan: plan,
        payment_cycle: payment_cycle,
        client_location: client_location,
      };
      //Date Formating


      editData.specification_fields.map((spec,index)=>{

        if(spec.data_type=='date'){
          console.log(finalEditPayload.specifications[spec.name]);

          finalEditPayload.specifications[spec.name] = moment(finalEditPayload.specifications[spec.name]).format("DD-MM-YYYY")

        }

      })

      //Formatting Boolean Value
      editData.specification_fields.map((spec, index) => {

        if (spec.data_type == 'yes_or_no') {
          console.log(finalEditPayload.specifications[spec.name.toLowerCase()]);

          finalEditPayload.specifications[spec.name] = finalEditPayload.specifications[spec.name] == true ? "Yes" : finalEditPayload.specifications[spec.name] == "Yes"?"Yes":"No"

        }

      })


      console.log(finalEditPayload, "edit values Final.....");





      confirmEditHandler(finalEditPayload, form);
    }
  };




  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      {!editData && !bulkUpdates && (
        <Form.Item
          key={editData?._id}
          labelCol={{ span: 24, offset: 0 }}
          name="template"
          label="Select Product Template"
          rules={[
            {
              required: true,
              message: "Missing Template",
            },
          ]}
        >
          <Select
            loading={templateLoading}
            onChange={(e) => onTemplateChange(e)}
            key={editData?._id}
            options={templateList}
          />
        </Form.Item>
      )}
      {!bulkUpdates && (
        <>
          <Form.Item
            label="Name"
            name="name"
            labelCol={{ span: 24, offset: 0 }}
            rules={[
               {
                validator: (_, value) => {
                  if (value && /un[-_\s]?r(?:egistere[d]?|egistere[d]?)/i.test(value)) {
                    return Promise.reject(new Error("Invalid product name"));
                  }
                  return Promise.resolve();
                },
    },
              {
                required: true,
                message: "Missing name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Barcode"
            name="barcode"
            labelCol={{ span: 24, offset: 0 }}
               rules={[
          {
      validator: (_, value) => {
        if (value && (/\b0000\b/.test(value))) {
          return Promise.reject(new Error("Invalid barcode"));
        }
        return Promise.resolve();
      },
    },     ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Brand"
            name="brand"
            labelCol={{ span: 24, offset: 0 }}
          >
          
            <Select
          showSearch
          options={brandList}
          style={{ width: "15rem" }}
        />
          </Form.Item>
        </>
      )}

      <Form.Item
        key={editData?._id}
        style={{ marginTop: "-1rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="plan"
        label="Plan"
        rules={[
          {
            required: true,
            message: "Missing Plan",
          },
        ]}
      >
        <Select
        disabled={editData?.plan=='RENT'}
          key={editData?._id}
          options={planList}
          style={{ width: "15rem" }}
        />
      </Form.Item>

      {!bulkUpdates && (
        <Form.Item
          style={{ marginTop: "-1rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="client_location"
          label="Client Location"
        >
          <Select
            key={editData?._id}
            options={clientLocationList}
            style={{ width: "15rem" }}
          />
        </Form.Item>
      )}

  

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        name="start_date"
        label="Start date"
        rules={[{ required: true }]}
        initialValue={startDate} // Set initial value
      >
        <Input
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          style={{ width: "15rem" }}
          type={"date"}
        />
      </Form.Item>

      {((currentTemplate || editData )&&  !bulkUpdates  ) && <Divider>Specifications</Divider>}

      { editData &&  !bulkUpdates   &&    currentFields?.map((field, index) => {
        console.log(currentFields, "current fields");

        console.log(typeof field.value, "Type");
        if (editData.specification_fields[index]?.data_type == "yes_or_no") {
          console.log(typeof field.value, "yes or no");

          return (
            <Form.Item
              style={{ width: "10rem" }}
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: editData.specification_fields[index]?. is_required
,
                  message: "Missing ",
                },
              ]}
              initialValue={(field?.value == 'Yes' || field?.value == true) ? true : false}
            >
              <Switch
                defaultChecked={(field?.value == 'Yes' || field?.value == true)?true:false}
                style={{ width: "6rem" }}
                checkedChildren="Yes"
                unCheckedChildren="No"
              ></Switch>
            </Form.Item>
          );
        } else if (editData.specification_fields[index]?.data_type == "date") {


  
          console.log(field.value, "Date");
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: editData.specification_fields[index]?.is_required,
                  message: 'Missing',
                },
              ]}
              initialValue={ moment(field.value,"DD-MM-YYYY")}

            >
              <DatePicker type="date" format={"DD-MM-YYYY"}     />
            </Form.Item>
          );
        } else {
          console.log("field?.name", currentFields)
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: editData.specification_fields[index]?.is_required,
                  message: "Missing ",
                },
              ]}
              initialValue={(field?.value) || ""}
            >
              <Input  type={field.data_type}  />
            </Form.Item>
          );
        }
      })}
      { currentTemplate && !editData &&  !bulkUpdates   &&    currentFields?.map((field, index) => {
        console.log("current fields", currentFields );

        console.log(typeof field.value, "Type");
        if (field.data_type == "yes_or_no") {
          console.log(typeof field.value, "yes or no");

          return (
            <Form.Item
              style={{ width: "10rem" }}
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required
,
                  message: "Missing ",
                },
              ]}
              initialValue={(field?.value == 'Yes' || field?.value == true) ? true : false}
            >
              <Switch
                defaultChecked={(field?.value == 'Yes' || field?.value == true)?true:false}
                style={{ width: "6rem" }}
                checkedChildren="Yes"
                unCheckedChildren="No"
              ></Switch>
            </Form.Item>
          );
        } else if (field.data_type == "date") {


  
          console.log(field.value, "Date");
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required,
                  message: 'Missing',
                },
              ]}
              initialValue={ moment(field.value,"DD-MM-YYYY")}

            >
              <DatePicker type="date" format={"DD-MM-YYYY"}     />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              key={field._id}
              label={editData ? field?.name.toUpperCase() : field?.name}
              
              name={field?.name}
              // hidden={field.name.toLowerCase() == "template"}
              rules={[
                {
                  required: field.is_required,
                  message: "Missing ",
                },
              ]}
              initialValue={(field?.value) || ""}
            >
              <Input  type={field.data_type}  />
            </Form.Item>
          );
        }
      })}


      <Form.Item>
        <Space size={8}>
          <Button key={editData?._id} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          ) : !bulkUpdates ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          ) : (
            <>
              <Tooltip
                title={selectedproducts.length ? "" : "Please select products first"}
              >
                <Button
                  disabled={!selectedproducts.length}
                  type="primary"
                  htmlType="submit"
                  loading={submitLoader}
                >
                  Update All
                </Button>
              </Tooltip>
            </>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ClientProductForm;
