const brands = () => {
    return [
      {
        title: "3D INNOVA",
        value: "3D INNOVA",
        _id: "3D INNOVA",
        description: "",
      },
      {
        title: "Acer",
        value: "Acer",
        _id: "Acer",
        description: "",
      },
      {
        title: "ADATA",
        value: "ADATA",
        _id: "ADATA",
        description: "",
      },
      {
        title: "AMD",
        value: "AMD",
        _id: "AMD",
        description: "",
      },
      {
        title: "Apple",
        value: "Apple",
        _id: "Apple",
        description: "",
      },
      {
        title: "Asus",
        value: "Asus",
        _id: "Asus",
        description: "",
      },
      {
        title: "Circle",
        value: "Circle",
        _id: "Circle",
        description: "",
      },
      {
        title: "Colourful",
        value: "Colourful",
        _id: "Colourful",
        description: "",
      },
      {
        title: "Cooler Master",
        value: "Cooler Master",
        _id: "Cooler Master",
        description: "",
      },
      {
        title: "Crucial",
        value: "Crucial",
        _id: "Crucial",
        description: "",
      },
      {
        title: "D link",
        value: "D link",
        _id: "D link",
        description: "",
      },
      {
        title: "DELL",
        value: "DELL",
        _id: "DELL",
        description: "",
      },
      {
        title: "EVM",
        value: "EVM",
        _id: "EVM",
        description: "",
      },
      {
        title: "Gigabyte",
        value: "Gigabyte",
        _id: "Gigabyte",
        description: "",
      },
      {
        title: "Hik Vision",
        value: "Hik Vision",
        _id: "Hik Vision",
        description: "",
      },
      {
        title: "Honeywell",
        value: "Honeywell",
        _id: "Honeywell",
        description: "",
      },
      {
        title: "HP",
        value: "HP",
        _id: "HP",
        description: "",
      },
      {
        title: "Hynix",
        value: "Hynix",
        _id: "Hynix",
        description: "",
      },
      {
        title: "IBALL",
        value: "IBALL",
        _id: "IBALL",
        description: "",
      },
      {
        title: "Intel",
        value: "Intel",
        _id: "Intel",
        description: "",
      },
      {
        title: "K7",
        value: "K7",
        _id: "K7",
        description: "",
      },
      {
        title: "Kaspersky",
        value: "Kaspersky",
        _id: "Kaspersky",
        description: "",
      },
      {
        title: "Kingston",
        value: "Kingston",
        _id: "Kingston",
        description: "",
      },
      {
        title: "Lenovo",
        value: "Lenovo",
        _id: "Lenovo",
        description: "",
      },
      {
        title: "LG",
        value: "LG",
        _id: "LG",
        description: "",
      },
      {
        title: "Logitech",
        value: "Logitech",
        _id: "Logitech",
        description: "",
      },
      {
        title: "NVIDIA",
        value: "NVIDIA",
        _id: "NVIDIA",
        description: "",
      },
      {
        title: "Quadro",
        value: "Quadro",
        _id: "Quadro",
        description: "",
      },
      {
        title: "Quick Heal",
        value: "Quick Heal",
        _id: "Quick Heal",
        description: "",
      },
      {
        title: "Samsung",
        value: "Samsung",
        _id: "Samsung",
        description: "",
      },
      {
        title: "Seagate",
        value: "Seagate",
        _id: "Seagate",
        description: "",
      },
      {
        title: "Sony",
        value: "Sony",
        _id: "Sony",
        description: "",
      },
      {
        title: "Sophos",
        value: "Sophos",
        _id: "Sophos",
        description: "",
      },
      {
        title: "Tp Link",
        value: "Tp Link",
        _id: "Tp Link",
        description: "",
      },
      {
        title: "Transcend",
        value: "Transcend",
        _id: "Transcend",
        description: "",
      },
      {
        title: "ViewSonic",
        value: "ViewSonic",
        _id: "ViewSonic",
        description: "",
      },
      {
        title: "Western Digital",
        value: "Western Digital",
        _id: "Western Digital",
        description: "",
      },
      {
        title: "ZOTAC",
        value: "ZOTAC",
        _id: "ZOTAC",
        description: "",
      },
      {
        title: "Unbranded",
        value: "Unbranded",
        _id: "Unbranded",
        description: "",
      }
  
    ];
  };
  
  export default brands;
  