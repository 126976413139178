import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch
} from "antd";

import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import useProductStore from "stores/productStore";
import useTemplateStore from "stores/templateStore";
import TemplateApi from "utils/composable/templateApi";

import Price from "components/Price/price";
import moment from "moment";
import useClientStore from "stores/clientStore";
import ProductApi from "utils/composable/productsApi";
const SalesForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
  vendorForm,
}) => {
  const templates = useTemplateStore((state) => state.templates);

  const [form] = Form.useForm();
  const [currentFields, setCurrentfields] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(false);
  const { addTemplate, getTemplates, updateTemplates } = TemplateApi();
  const { getProducts, getProductsWithStatusAndUse } = ProductApi();
  const [priceData, setPriceData] = useState("");
  const [clientList, setClientList] = useState([]);
  const [currentBarcode, setCurrentBarcode] = useState("");
  const [saleProductId, setSaleProductId] = useState();
  const [currentSaleProduct, setCurrentSaleProduct] = useState({
    specifications: {},
  });
  const [productList, setProductList] = useState()

  //productStore
  const products = useProductStore((state) => state.Products).filter(
    (product) => product.status == "in_stock" && product.use == "sale"
  );



  const templateList = templates.map((template) => {
    return {
      label: template.name,
      value: template._id,
    };
  });



  const clients = useClientStore((state) => state.clients);

  useEffect(() => {
    getProducts().then(()=>{

      const productList = products
        .filter((product) => product.status == "in_stock"&& product.use=='sale' )
        .map((product) => {
          
            return {
              label: product.name,
              value: product._id,
            }
          
        });

      setProductList(productList)

    });

    var clientList = clients.map((client) => {
      return {
        label: `${client.name}`,
        value: client._id,
        key: client._id,
      };
    });

    setClientList(clientList);
  }, [editData]);




  useEffect(() => {
   
    console.log(currentSaleProduct);


  }, [currentSaleProduct])
  





  useEffect(() => {
    if (!templates.length) {
      // setTableLoadings(true);

      try {
        getTemplates()
          .then(() => {
            // setTableLoadings(false);
          })
          .catch((err) => {
            console.log(err);

            // setTableLoadings(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (editData) {
  
      var fields = Object.entries(editData.specifications);
      var currentEditFields = fields.map((field) => {
        return {
          name: field[0],
          value: field[1],
        };
      });


      // currentEditFields.shift();


      setCurrentfields(currentEditFields);
    }
  }, []);

  const onFinish = (values) => {
 

    if (!editData) {

      const {
        client,

        description,
        warranty_date,
        sold_date,
        amount,
        name,
        category,
        barcode,
        brand,
        charges,
        discount_percentage,
        item_price,
        qty,
        taxes,
        ...specifications
      } = values;

      const finalPayload = {
        specifications,
        name,
        category,
        barcode,
        brand,
        client,
        sold_date: moment(sold_date).format("MM-DD-YYYY"),
        category: currentTemplate?.category?._id,
        description,
        warranty_date: moment(warranty_date).format("MM-DD-YYYY"),
        price: priceData,
      };

      finalPayload.clientData = clients.find(
        (client) => client._id == values.client
      );
      finalPayload.productData = products.find(
        (product) => product._id == values.product
      );
      if (vendorForm) {
        finalPayload.vendor = true;
      } else {
        let currentProduct = products.find(
          (product) => product._id == saleProductId
        );

        finalPayload.saleProductId = saleProductId;
        finalPayload.name = currentProduct.name;
        finalPayload.category = currentProduct.category;
        finalPayload.barcode = currentProduct.barcode;
        finalPayload.specifications = currentProduct.specifications;
      }

 

      submitHandler(finalPayload, form);
    } else {
      const {
        client,
        description,
        warranty_date,
        sold_date,
        template,
        amount,
        name,
        category,
        barcode,
        brand,
        charges,
        discount_percentage,
        item_price,
        qty,
        taxes,
        ...specifications
      } = values;

      const finalEditPayload = {
        specifications,
        name,
        category,
        barcode,
        brand,
        client,
        description,
        warranty_date: moment(warranty_date).format("DD-MM-YYYY"),
        sold_date: moment(sold_date).format("DD-MM-YYYY"),
        template,

        amount: parseFloat(amount),
      };

      // confirmEditHandler(finalEditPayload, form);
    }
  };

  const onTemplateChange = (e) => {
    console.log(e);

    const selectedTemplate = templates.find((template) => template._id == e);


    setCurrentTemplate(selectedTemplate);

    setCurrentfields(selectedTemplate.fields);


  };

  const onProductChange = (productId) => {
    setSaleProductId(productId);
    setCurrentSaleProduct(products.find((product) => product._id == productId));


  };

  useEffect(() => {
    vendorForm && setCurrentSaleProduct({ specifications: {} });
  }, [vendorForm]);

  //Fuzzy filter


  const alphabeticFilter = (query) => {
    const filteredClients = clients.filter((client) =>
      client.name.toLowerCase().includes(query.toLowerCase())
    );

    let clientList = filteredClients.map((client) => {
      return {
        label: `${client.name}`,
        value: client._id,
        key: client._id,

      };
    });




    setClientList(clientList)
 
  };

  const fuzzyFilter = (query, product) => {
    if (query !== "") {

      var get_bigrams, j, len, obj, relevance, results, string_similarity;

      get_bigrams = function (string) {
 
        var i, j, ref, s, v;
        s = string.toLowerCase();
        v = new Array(s.length - 1);
        for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
          v[i] = s.slice(i, i + 2);
        }
        return v;
      };

      string_similarity = function (str1, str2) {


        var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
        if (str1.length > 0 && str2.length > 0) {
          pairs1 = get_bigrams(str1);
          pairs2 = get_bigrams(str2);
          union = pairs1.length + pairs2.length;
          hit_count = 0;
          for (j = 0, len = pairs1.length; j < len; j++) {
            x = pairs1[j];
            for (k = 0, len1 = pairs2.length; k < len1; k++) {
              y = pairs2[k];
              if (x === y) {
                hit_count++;
              }
            }
          }
          if (hit_count > 0) {
            return (2.0 * hit_count) / union;
          }
        }
        return 0.0;
      };

      if (query.length >= 2) {
        results = [];

        for (j = 0, len = clients.length; j < len; j++) {
          var client = clients[j];


          relevance = string_similarity(query, client?.name || "");

          obj = {
            ...client,
            relevance: relevance,
          };
          results.push(obj);
        }

        var sortedResults = results.filter((result) => result.relevance > 0.1);
        sortedResults = sortedResults
          .sort((a, b) => a.relevance - b.relevance)
          .reverse();


        //  clientList= ([...sortedResults])

        let clientList = sortedResults.map((client) => {
          return {
            label: `${client.name}`,
            value: client._id,
            key: client._id,
          };
        });

        setClientList(clientList);
      } else {
        let clientList = clients.map((client) => {
          return {
            label: `${client.name}`,
            value: client._id,
            key: client._id,
          };
        });

        setClientList(clientList);
      }
    }
  };


  const productFuzzyFilter = (query, product) => {


    if (query !== '') {
  
      var get_bigrams, j, len, obj, relevance, results, string_similarity;


      get_bigrams = function (string) {

        var i, j, ref, s, v;
        s = string.toLowerCase();
        v = new Array(s.length - 1);
        for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
          v[i] = s.slice(i, i + 2);
        }
        return v;
      };


      string_similarity = function (str1, str2) {


        var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
        if (str1.length > 0 && str2.length > 0) {
          pairs1 = get_bigrams(str1);
          pairs2 = get_bigrams(str2);
          union = pairs1.length + pairs2.length;
          hit_count = 0;
          for (j = 0, len = pairs1.length; j < len; j++) {
            x = pairs1[j];
            for (k = 0, len1 = pairs2.length; k < len1; k++) {
              y = pairs2[k];
              if (x === y) {
                hit_count++;
              }
            }
          }
          if (hit_count > 0) {
            return (2.0 * hit_count) / union;
          }
        }
        return 0.0;
      };


      if (query.length >= 2) {
        results = [];

        for (j = 0, len = products.length; j < len; j++) {
          var product = products[j];


          relevance = string_similarity(query, product?.name || "");



          obj = {
            ...product,
            relevance: relevance
          };
          results.push(obj);
        }

        var sortedResults = results.filter((result) => result.relevance > 0.1)
        sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()



        //  clientList= ([...sortedResults])

       let productList = sortedResults
          .filter((product) => product.status == "in_stock"&& product.use=='sale' )
          .map((product, index) => {
            if (!product.sold_on) {
              return {
                label: product.name,
                value: product._id,
                key: product._id,
                index: index
              };
            } else return {};
          });


        setProductList(productList)


      } else {




        productList = products.map((product) => {
          return {
            label: `${product.name}`,
            value: product._id,
            key: product._id,

          };
        });


        setProductList(productList)


      }

    }
  }

  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      {!editData && vendorForm && (
        <Form.Item
          key={editData?._id}
          labelCol={{ span: 24, offset: 0 }}
          style={{ width: "42rem" }}
          label="Select Product Template"
          rules={[
            {
              required: true,
              message: "Missing Template",
            },
          ]}
        >
          <Select
            style={{ width: "42rem" }}
            onChange={(e) => onTemplateChange(e)}
            key={editData?._id}
            options={templateList}
          />
        </Form.Item>
      )}

      {!vendorForm && (
        <Form.Item
          key={editData?._id}
          labelCol={{ span: 24, offset: 0 }}
          style={{ width: "42rem" }}
          label="Select Product "
          rules={[
            {
              required: true,
              message: "Missing Product",
            },
          ]}
        >
          {/* <Select
            style={{ width: "42rem" }}
            onChange={(e) => onProductChange(e)}
            key={editData?._id}
            options={productList}
          /> */}



          <Select


            style={{ width: '21rem' }}
            onChange={(e) => onProductChange(e)}
            onSearch={(input) => {

              productFuzzyFilter(input)

            }}
            showSearch
            placeholder="Select a Product"
            optionFilterProp="children"
            options={productList}
            filterOption={(input, data) => {


              return true

            }

            }

          ></Select>



        </Form.Item>
      )}

      <Form.Item
        style={{ width: "42rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="client"
        label="Client Name"
        rules={[{ required: true }]}
      >
        <Select
          style={{ width: "42rem" }}
          disabled={editData}
          onSearch={(input) => {
            alphabeticFilter(input);
          }}
          showSearch
          placeholder="Select a client"
          optionFilterProp="children"
          options={clientList}
          filterOption={(input, clientList) => {
            return true;
          }}
        ></Select>
      </Form.Item>

      {vendorForm && (
        <Space>
          <Form.Item
            style={{ width: "21rem" }}
            label="Product Name"
            name="name"
            labelCol={{ span: 24, offset: 0 }}
            rules={[
              {
                required: true,
                message: "Missing name",
              },
            ]}
          >
            <Input style={{ width: "21rem" }} />
          </Form.Item>

          <Form.Item
            style={{ width: "21rem" }}
            label="Barcode"
            name="barcode"
            labelCol={{ span: 24, offset: 0 }}
          >
            <Input
              onChange={(e) => setCurrentBarcode(e.target.value)}
              style={{ width: "21rem" }}
            />
          </Form.Item>
        </Space>
      )}

      <Space>
        {vendorForm && (
          <Form.Item
            style={{ width: "21rem" }}
            label="Brand"
            name="brand"
            labelCol={{ span: 24, offset: 0 }}
          >
            <Input style={{ width: "21rem" }} />
          </Form.Item>
        )}

        <Form.Item
          style={{ width: "21rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="sold_date"
          label="Sold Date"
          rules={[{ required: true }]}
        >
          <Input style={{ width: "21rem" }} type={"date"} />
        </Form.Item>
      </Space>

      <Space></Space>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        name="warranty_date"
        label="Warranty Date"
      >
        <Input style={{ width: "21rem" }} type={"date"} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Description"
        name="description"
      >
        <TextArea />
      </Form.Item>

      <Price setPriceData={setPriceData} sellingProduct={currentSaleProduct} vendorForm={vendorForm}/>

      {(currentTemplate || editData || currentSaleProduct) && (
        <Divider>Specifications</Divider>
      )}

      {(!vendorForm && currentSaleProduct?.specifications   )  ? (
        <div>
          {Object?.keys(currentSaleProduct?.specifications)?.map((key, i) => (
            <p key={i}>
              <span
                style={{ fontWeight: "bolder" }}
              >{`  :  ${currentSaleProduct?.specifications[key]}`}</span>
              {""}
              <span style={{ float: "inline-start" }}>{" " + key}</span>
            </p>
          ))}
        </div>
      ) : (
        ""
      )}

      {vendorForm &&
        currentFields?.map((field, index) => {
       

          if (field.data_type == "yes_or_no") {
        

            return (
              <Form.Item
                style={{ width: "10rem" }}
                labelCol={{ span: 24, offset: 0 }}
                key={field._id}
                label={editData ? field?.name.toUpperCase() : field?.name}
                name={field?.name?.toLowerCase()}
                // hidden={field.name.toLowerCase() == "template"}
                rules={[
                  {
                    required: field.is_required,
                    message: "Missing ",
                  },
                ]}
                initialValue={false}
              >
                <Switch
                  defaultChecked={field?.value}
                  style={{ width: "6rem" }}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                ></Switch>
              </Form.Item>
            );
          } else if (field.data_type == "date") {

            return (
              <Form.Item
                labelCol={{ span: 24, offset: 0 }}
                key={field._id}
                label={editData ? field?.name.toUpperCase() : field?.name}
                name={field?.name?.toLowerCase()}
                // hidden={field.name.toLowerCase() == "template"}
                rules={[
                  {
                    required: field.is_required,
                    message: "Missing ",
                  },
                ]}
                initialValue={field?.value || ""}
              >
                <Input type="date" />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                labelCol={{ span: 24, offset: 0 }}
                key={field._id}
                label={editData ? field?.name.toUpperCase() : field?.name}
                name={field?.name?.toLowerCase()}
                // hidden={field.name.toLowerCase() == "template"}
                rules={[
                  {
                    required: field.is_required,
                    message: "Missing ",
                  },
                ]}
                initialValue={field?.value || ""}
              >
                <Input type={field.data_type} />
              </Form.Item>
            );
          }
        })}

      {(currentTemplate || editData) && <Divider></Divider>}

      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button key={editData._id} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SalesForm;
